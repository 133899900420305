import React, { useEffect, useState } from 'react'
import images from 'assets/images'
// import { Popup /* or Slider */ } from 'react-typeform-embed'
import useResizeWidth from 'controller/hooks/useResizeWidth'

import styles from './Header.module.scss'
import { PopupButton } from '@typeform/embed-react'

function Header() {
  const { isMobile } = useResizeWidth()
  const [isShowMenu, setIsShowMenu] = useState(false)

  const handleShowMenu = () => {
    if (isMobile) {
      setIsShowMenu(!isShowMenu)
    } else {
      return null
    }
  }

  useEffect(() => {
    if (!isMobile) {
      setIsShowMenu(false)
    }
  }, [isMobile])

  return (
    <div className={styles.header}>
      <div className='header-container'>
        <div className='left-logo'>
          <img
            src={images[!isMobile ? 'logoFull' : 'logo']}
            alt=''
            className='logo'
          />
        </div>

        <img
          src={images[!isShowMenu ? 'hambuger' : 'iconClose']}
          alt=''
          className='icon-dashboard'
          onClick={handleShowMenu}
        />

        <div className={`right-menu ${isShowMenu && 'right-menu--show'}`}>
          <div className='item-menu item-menu--coming'>
            Portfolio
            <div className='tooltip'>Coming Soon</div>
          </div>
          <div className='item-menu'>
            <a
              href='https://blog.vietnamfuture.fund/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Writing
            </a>
          </div>
          <div className='item-menu item-menu--build-with-us'>
            {/* <Popup id='https://fftie5a3xdx.typeform.com/to/Lp1xOM8s'> */}
            <PopupButton id='https://tally.so/r/wb9RA0' className='button-link'>
              Build with us
            </PopupButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
