import MyButton from 'assets/components/MyButton'
import './styles/global.scss'
import images from 'assets/images'
import useResizeWidth from 'controller/hooks/useResizeWidth'
import { useEffect, useRef, useState } from 'react'
// import { Popup /* or Slider */ } from 'react-typeform-embed'
import { PopupButton } from '@typeform/embed-react'

const arrowArr = Array(2000)
  .fill()
  .map((item, i) => {
    return {
      index: i + 1,
      icon: images.arrowIcon,
    }
  })

function App() {
  const { widthResize } = useResizeWidth()
  const isMobile = widthResize <= 768

  // const arrowElm = document.querySelectorAll('.icon-arrow')

  // function rotateArrow(e) {
  //   if (isMobile) {
  //     return null
  //   } else {
  //     arrowElm.forEach(function (arrow) {
  //       let x = arrow.getBoundingClientRect().left + arrow.clientHeight / 2
  //       let y = arrow.getBoundingClientRect().top + arrow.clientHeight / 2
  //       let radian = Math.atan2(e.pageX - x, e.pageY - y)
  //       let rot = radian * (180 / Math.PI) * -1 + 145
  //       arrow.style.transform = `rotate(${rot}deg)`
  //     })
  //   }
  // }

  // const renderArrow = () => {
  //   return arrowArr.map((item, i) => {
  //     // return <img src={item.icon} alt='' className='icon-arrow' key={i} />
  //     return <span alt='' className='icon-vector icon-arrow' key={i} />
  //   })
  // }

  // useEffect(() => {
  //   window.addEventListener('mousemove', rotateArrow)
  // })

  return (
    <div className='app'>
      <div>
        <div className='title-content'>
          Beyond Funding. <br />
          Beyond Vietnam.
        </div>
        <div className='btn-action'>
          <PopupButton
            // id='https://fftie5a3xdx.typeform.com/to/Lp1xOM8s'
            id='https://tally.so/r/wb9RA0'
            // className='btn-popup'
          >
            <MyButton>
              Build with us
              <img src={images.logo} alt='' className='icon-logo-btn' />
            </MyButton>
          </PopupButton>
        </div>
      </div>

      <div>
        <div className='text-description'>
          Vietnam Future Fund is an investment firm dedicated to empowering
          Vietnamese entrepreneurs to build globally-focused companies. We
          provide funding, resources, and guidance to help Vietnamese businesses
          expand internationally and make a global impact.
        </div>

        <div className='btn-mobile'>
          <PopupButton
            id='https://tally.so/r/wb9RA0'
            // id='https://fftie5a3xdx.typeform.com/to/Lp1xOM8s'
            className='btn-popup'
          >
            <MyButton className='custom-btn'>
              Build with us
              <img src={images.logo} alt='' className='icon-logo-btn' />
            </MyButton>
          </PopupButton>
        </div>
      </div>

      {/* <div className='background-img'>{renderArrow()}</div> */}

      <div className='icon-logo'>
        <img src={images.circle} alt='' className='circle circle--big' />
        <img src={images.circle} alt='' className='circle circle--small' />
      </div>
    </div>
  )
}

export default App
