import React from 'react'

import styles from './MyButton.module.scss'
import classNames from 'classnames'

function MyButton({ className, children, onClick }) {
  return (
    <div className={classNames(styles.myButton, className)}>
      <button className='button-content' onClick={onClick}>
        {children}
      </button>
    </div>
  )
}

export default MyButton
