import React from 'react'

import styles from './Footer.module.scss'
import images from 'assets/images'

const arrMenu = [
  {
    name: 'Email us',
    link: 'hi@vietnamfuture.fund',
    isEmail: true,
    icon: '',
  },
  {
    name: 'Facebook',
    link: 'https://www.facebook.com/vnfuturefund',
    isEmail: false,
    icon: 'icon-facebook',
  },
  {
    name: 'Twitter',
    link: 'https://twitter.com/vnfuturefund',
    isEmail: false,
    icon: 'icon-twitter',
  },
]

function Footer() {
  const renderMenu = () => {
    return arrMenu.map((item, i) => {
      return (
        <div key={i} className='item-menu'>
          {!item.isEmail ? (
            <a href={item.link} target='_blank' rel='noreferrer'>
              {item.icon && <span className={`${item.icon} icon`} alt='' />}{' '}
              {item.name}
            </a>
          ) : (
            <a href='mailto:hi@vietnamfuture.fund'>{item.name}</a>
          )}
        </div>
      )
    })
  }

  return (
    <div className={styles.footer}>
      <div className='footer-container'>
        <div className='menu-list'>{renderMenu()}</div>
      </div>
    </div>
  )
}

export default Footer
